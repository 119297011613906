import axios from "axios";
import router from "../router";
require("dotenv").config();
export default {

    namespaced: true,
    state: {
        bonusId: null,
        dashboardData: null,
        noDataMessage: '', // Сообщение об отсутствии данных
        isLoading: true,
        isLoadingGuest: true,
        tableData: null,
        downloadDataTable:[],
        search: '',
        sort: null,
        dateFilter: { from: null, to: null },
        dataTypePeriod: null,
        totalPages: null,
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: null,
    },
    getters: {
        getDashboardData: (state) => state.dashboardData,
        getTableData: (state) => state.tableData,
        getSearchQuery: (state) => state.search,
        getSort: (state) => state.sort,
        getDateFilter: (state) => state.dateFilter,
        getPagination: (state) => ({
            currentPage: state.currentPage,
            itemsPerPage: state.itemsPerPage,
        }),
        getTotalItems: (state) => state.totalItems,
        getTotalPages: (state) => state.totalPages,
        getDateTypePeriod: (state) => state.dataTypePeriod,
    },
    actions: {
        setBonusId({ commit }, bonusId) {
            commit('SET_BONUS_ID', bonusId);
          },
          // Получение данных Dashboard
        fetchDashboardData({ commit, state }) {
        commit('SET_LOADING', true);
        // Первый запрос - получение информации о клиентах
        axios.get('/api/Dashboard/GetBonusGroupStatistics', {
        params: {
          bonusGroupId: state.bonusId,
          startDate: state.dateFilter?.from,
          endDate: state.dateFilter?.to,
          dataType: state.dataTypePeriod
        }
      })
        .then((response) => {
          if (response.status === 204 || !response.data || response.data.clientAmount.amount === 0 ) {
            commit('SET_NO_DATA_MESSAGE', 'За выбранный период данных нет.');
            commit('SET_DASHBOARD_DATA', {});
          } else {
            commit('SET_DASHBOARD_DATA', response.data);
            commit('SET_NO_DATA_MESSAGE', '');
            commit('SET_LOADING', false);
          }
        })
        .catch((error) => {
          console.error('Error fetching dashboard information:', error);
        });
          },
          // Получение данных таблицы
          fetchTableData({ commit, state }) {
            commit('SET_TABLE_DATA', {});
            const { bonusId, search, sort, dateFilter, currentPage, itemsPerPage, dataTypePeriod } = state;
            commit('SET_LOADING_GUEST', true);
            axios
              .put('/api/Dashboard/GetBonusGroupClientsPagedList', {
                pageNumber: currentPage,
                pageSize: itemsPerPage,
                filters: [
                  {
                    property: 'PhoneNumber',
                    operator: 'contains',
                    value: search.replace(/\s+/g, ''),
                  },
                ],
                sorting: sort
                  ? {
                      property: sort.property,
                      isDescending: sort.isDescending,
                    }
                  : null,
              }, {
                params: {           
                    bonusGroupId: bonusId,
                    startDate: dateFilter?.from,
                    endDate: dateFilter?.to,
                    dataType: dataTypePeriod,
                },
              })
              .then((response) => {
                if (response.status === 204 || !response.data) {
                    commit('SET_TABLE_DATA', {});
                    commit('SET_TOTAL_ITEMS', 0);
                    commit('SET_TOTAL_PAGES', 0);
                  } else {
                    commit('SET_TABLE_DATA', response.data);
                    commit('SET_TOTAL_ITEMS', response.data.totalCount);
                    commit('SET_TOTAL_PAGES', response.data.totalPages);
                    commit('SET_LOADING_GUEST', false);
                  }
              })
              .catch((error) => {
                console.error('Ошибка при загрузке данных таблицы:', error);
              });
          },

          fetchTableDataDownload({ commit, state }) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING_GUEST', true); // Устанавливаем загрузку
        
                const { bonusId, sort, dateFilter, dataTypePeriod, totalItems } = state;
        
                axios
                    .put(
                        '/api/Dashboard/GetBonusGroupClientsPagedList',
                        {
                            pageNumber: 1,
                            pageSize: 100,
                            sorting: sort
                                ? {
                                      property: sort.property,
                                      isDescending: sort.isDescending,
                                  }
                                : null,
                        },
                        {
                            params: {
                                bonusGroupId: bonusId,
                                startDate: dateFilter?.from,
                                endDate: dateFilter?.to,
                                dataType: dataTypePeriod,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 204 || !response.data) {
                            console.warn("Данные отсутствуют или ответ пуст:", response.data);
        
                            // commit('SET_TABLE_DATA', {}); // Очищаем данные таблицы
                            commit('SET_TABLE_DATA_DOWNLOAD', []); // Очищаем загрузочные данные
                            commit('SET_TOTAL_ITEMS', 0);
                            commit('SET_TOTAL_PAGES', 0);
                        } else {
                            console.log("Данные успешно загружены:", response.data);
        
                            // commit('SET_TABLE_DATA', response.data);
                            commit('SET_TABLE_DATA_DOWNLOAD', response.data.items);
                            commit('SET_TOTAL_ITEMS', response.data.totalCount || 0);
                            commit('SET_TOTAL_PAGES', response.data.totalPages || 0);
                        }
                        resolve(); // Успешное завершение
                    })
                    .catch((error) => {
                        console.error("Ошибка при загрузке данных таблицы:", error);
        
                        // Сбрасываем данные в случае ошибки
                        commit('SET_TABLE_DATA', {});
                        commit('SET_TABLE_DATA_DOWNLOAD', []);
                        commit('SET_TOTAL_ITEMS', 0);
                        commit('SET_TOTAL_PAGES', 0);
        
                        reject(error); // Сообщаем об ошибке
                    })
                    .finally(() => {
                        commit('SET_LOADING_GUEST', false); // Завершаем загрузку
                    });
            });
        },
          // Обновление текущей страницы
          setCurrentPage({ commit }, page) {
            commit('SET_CURRENT_PAGE', page);
          },
          // Обновление количества элементов на странице
          setItemsPerPage({ commit }, items) {
            commit('SET_ITEMS_PER_PAGE', items);
          },
          // Обновление поискового запроса
          setSearchQuery({ commit }, query) {
            commit('SET_SEARCH_QUERY', query);
          },
          // Установка сортировки
          setSort({ commit }, sort) {
            commit('SET_SORT', sort);
          },
          // Установка фильтра по дате
          setDateFilter({ commit }, filter) {
            commit('SET_DATE_FILTER', filter);
          },
          setTotalItems({ commit }, totalItems){
            commit('SET_TOTAL_ITEMS', totalItems);
          },
          setDateTypePeriod({commit}, dataTypePeriod) {
            commit('SET_DATE_TYPE', dataTypePeriod);
          },
          SetTotalPages({commit}, totalPages) {
            commit('SET_TOTAL_PAGES', totalPages)
          }
    },
    mutations: {
        SET_BONUS_ID(state, bonusId) {
            state.bonusId = bonusId;
          },
          SET_TOTAL_PAGES (state, pages) {
            // console.log("Обновление totalPages:", pages);
            state.totalPages = pages
          },
          SET_DASHBOARD_DATA(state, data) {
            state.dashboardData = data;
          },
          SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
          },
          SET_LOADING_GUEST(state, isLoadingGuest) {
            state.isLoadingGuest = isLoadingGuest;
          },
          SET_NO_DATA_MESSAGE(state, message) {
            state.noDataMessage = message;
          },
          SET_TABLE_DATA(state, data) {
            state.tableData = data;
          },
          SET_TABLE_DATA_DOWNLOAD(state, data) {
            if (Array.isArray(data)) {
              // console.log('Данные успешно записаны в downloadDataTable:', data);
              state.downloadDataTable = data;
            } else {
              console.warn('Попытка записать не массив в downloadDataTable. Получено:', data);
              state.downloadDataTable = [];
            }
          },
          SET_SEARCH_QUERY(state, query) {
            state.search = query;
          },
          SET_SORT(state, sort) {
            state.sort = sort;
          },
          SET_DATE_FILTER(state, filter) {
            state.dateFilter = filter;
          },
          SET_CURRENT_PAGE(state, page) {
            state.currentPage = page;
          },
          SET_ITEMS_PER_PAGE(state, items) {
            state.itemsPerPage = items;
          },
          SET_TOTAL_ITEMS(state, total) {
            // console.log("Обновление totalItems:", total);
            state.totalItems = total;
          },
          SET_DATE_TYPE(state, type) {
            state.dataTypePeriod = type;
          }
    },

}